/**
 * Labstep
 *
 * @module components/Entity/PrimaryInfo
 * @desc Primary Info section of entity
 */

import PermaLinkActionCopy from 'labstep-web/components/PermaLink/Action/Copy';
import { ICONS } from 'labstep-web/constants/icons';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import Flex from 'labstep-web/core/Flex';
import ShowEditText from 'labstep-web/core/Form/ShowEdit/Text';
import Header from 'labstep-web/core/Header';
import Hoverable from 'labstep-web/core/Hoverable';
import Icon from 'labstep-web/core/Icon';
import { useToggle } from 'labstep-web/hooks/toggle';
import React from 'react';
import { ResourceActionAddCustomIdentifier } from '../Action/AddCustomIdentifier';
import { fieldEntityName } from './fields';
import styles from './styles.module.scss';
import { IResourcePrimaryInfoProps } from './types';

export const convertToHeader = (children: React.ReactNode) => (
  <Header size="small" noMargin>
    {children}
  </Header>
);

const ResourcePrimaryInfo: React.FC<IResourcePrimaryInfoProps> = ({
  resource,
  prefix,
  icon,
  withPermaLink = true,
  name,
  disabled = false,
}) => {
  const [isToggled, toggle] = useToggle();
  return (
    <Hoverable
      className={styles.container}
      hoverable={
        <>
          <Icon
            name={icon || ICONS[resource.entityName].primary}
            size="large"
          />
          {prefix && convertToHeader(prefix)}
          {prefix && name && convertToHeader('-')}
          {(isToggled || name || (!prefix && !name)) && (
            <ShowEditText
              disabled={disabled}
              initialEditingState={isToggled}
              entity={resource}
              entityName={resource.entityName}
              field={fieldEntityName}
              placeholder="Untitled Resource"
              onToggle={toggle}
              options={{
                onSuccess: () => {
                  toggle();
                },
              }}
            >
              {convertToHeader(name)}
            </ShowEditText>
          )}
        </>
      }
      component={
        disabled ? (
          <></>
        ) : (
          <Flex vAlignContent="center" marginLeft={10}>
            {!isToggled && !name && prefix && (
              <ActionComponent
                type="text"
                icon="plus"
                text="Add name"
                onClick={toggle}
              />
            )}
            {resource.template &&
              resource.template.custom_identifier_set &&
              !resource.custom_identifier && (
                <ResourceActionAddCustomIdentifier
                  resource={resource}
                />
              )}
            {withPermaLink ? (
              <PermaLinkActionCopy entity={resource} />
            ) : undefined}
          </Flex>
        )
      }
    />
  );
};

export default ResourcePrimaryInfo;
