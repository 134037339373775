/**
 * Labstep
 *
 * @module components/Signature/Item
 * @desc Signature item
 */

import ProfilePictureImage from 'labstep-web/components/Profile/Picture/Image';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import Card from 'labstep-web/core/Card/Generic';
import { DateSmall } from 'labstep-web/core/DatePreview';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import { Size } from 'labstep-web/core/Initials/types';
import Pre from 'labstep-web/core/Pre';
import TextError from 'labstep-web/core/Text/Error';
import { useToggle } from 'labstep-web/hooks/toggle';
import { dateOnly } from 'labstep-web/services/date.service';
import React from 'react';
import SignatureActionMenu from '../Action/Menu';
import styles from './styles.module.scss';
import { ISignatureCardProps, SignatureStatus } from './types';

export const SignatureCard: React.FC<ISignatureCardProps> = ({
  signature,
  entityUserRole,
  experimentWorkflow,
  authenticatedUserEntity,
}) => {
  const [isToggled, toggle] = useToggle();
  const isRejected = !!signature.is_reject;

  return (
    <Card
      className={isRejected ? styles.cardRejected : styles.cardSigned}
      headerSuper={
        <Flex spacing="between">
          <Flex
            spacing="gap"
            vAlignContent="center"
            hAlignContent="left"
          >
            <Icon name="edit" size="large" />
            <ProfilePictureImage
              entity={signature.author}
              size={Size.mini}
            />
            <Flex column>
              <span className={styles.bold}>
                {signature.author.name}
              </span>
              <span>{entityUserRole.name}</span>
            </Flex>
          </Flex>
          <Flex
            data-testid="right-hand-side"
            spacing="gap"
            hAlignContent="center"
            vAlignContent="center"
          >
            <Flex column hAlignContent="right">
              <Flex>
                <span className={styles.bold}>
                  {isRejected
                    ? SignatureStatus.rejected.toString()
                    : SignatureStatus.signed.toString()}
                </span>
                <Icon
                  name={isToggled ? 'chevron up' : 'chevron down'}
                  onClick={() => {
                    toggle();
                  }}
                />
              </Flex>
              <DateSmall dateString={signature.updated_at} />
            </Flex>

            {authenticatedUserEntity.id === signature.author.id && (
              <SignatureActionMenu
                signature={signature}
                experimentWorkflow={experimentWorkflow}
                actions={['revoke']}
              />
            )}
          </Flex>
        </Flex>
      }
      body={
        signature.statement &&
        signature.statement !== '' &&
        isToggled ? (
          <Flex marginTop="20px">
            <div>
              <Pre>{signature.statement}</Pre>
              <span>
                <DateSmall dateString={signature.created_at} />
                {signature.revoked_at && (
                  <TextError>
                    <b>
                      {' '}
                      Revoked on {dateOnly(signature.revoked_at)}
                    </b>
                  </TextError>
                )}
              </span>
            </div>
          </Flex>
        ) : null
      }
    />
  );
};

export default withAuthenticatedUserEntity(SignatureCard);
