/**
 * Labstep
 *
 * @module core/Card/EmptyState
 * @desc Skeleton for empty state warnings
 */

import React from 'react';
import classnames from 'classnames';
import Header from 'labstep-web/core/Header';
import Image from 'labstep-web/core/Image';
import styles from './styles.module.scss';
import { IEmptyStateProps } from './types';

const EmptyState: React.FC<IEmptyStateProps> = ({
  title,
  src,
  explanation,
  action,
  secondaryAction,
  warning,
  stackedActions = false,
}) => (
  <div className={styles.main} data-testid="empty-state">
    <Image src={src} fluid />
    {title && (
      <Header size="xsmall" color={warning ? 'red' : undefined}>
        {title}
      </Header>
    )}
    <p>{explanation}</p>
    <div
      className={classnames(styles.actions, {
        [styles.stackedActions]: stackedActions,
        [styles.withoutSecondaryAction]: !secondaryAction,
      })}
    >
      <div>{action}</div>
      {secondaryAction && <div>{secondaryAction}</div>}
    </div>
  </div>
);

export default EmptyState;
