/**
 * Labstep
 *
 * @module components/WorkflowStatusIndicator/Main
 * @desc A component for the signature workflow status indicator
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { UPDATE_ENTITY_STATE_MODAL_ROUTE_ID } from 'labstep-web/components/ExperimentWorkflow/Action/UpdateEntityState/ModalWizard';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import DropdownPill from 'labstep-web/core/Dropdown/Pill';
import Flex from 'labstep-web/core/Flex';
import Status from 'labstep-web/core/Status';
import { StatusPosition } from 'labstep-web/core/Status/types';
import { useModalRoute } from 'labstep-web/hooks/modalRoute';
import { EntityState } from 'labstep-web/models/entity-state.model';
import { IStatus, IUpdateAction } from 'labstep-web/typings';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { SemanticICONS } from 'semantic-ui-react';
import styles from './styles.module.scss';
import {
  IWorkflowStatusIndicatorProps,
  WorkflowStatusIndicatorSize as Size,
} from './types';
import { handleResize } from './utils';

const WorkflowStatusIndicator: React.FC<
  IWorkflowStatusIndicatorProps
> = ({ experimentWorkflow }) => {
  const divRef = useRef(null);
  const [size, setSize] = useState<Size>(Size.large);
  const canEdit = useHasAccess(
    experimentWorkflow.entityName,
    experimentWorkflow.id,
    'experiment_workflow:edit:entity_state' as Action,
  );
  const { toggleModal } = useModalRoute(
    UPDATE_ENTITY_STATE_MODAL_ROUTE_ID,
  );

  const entityStates =
    experimentWorkflow.entity_state_workflow?.entity_states || [];

  const currentEntityState = experimentWorkflow.entity_state;

  const [activeIndexes, setActiveIndexes] = useState<number[]>([
    currentEntityState
      ? entityStates.findIndex(
          (state) => currentEntityState.id === state.id,
        )
      : 0,
  ]);

  useEffect(() => {
    const observer = new ResizeObserver(
      debounce((entries) => {
        handleResize(
          entityStates.length,
          setSize,
          entries[0].contentRect.width,
        );
      }),
    );

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, [entityStates]);

  useEffect(() => {
    if (!currentEntityState) {
      return;
    }
    const newIndex = entityStates.findIndex(
      (state) => currentEntityState.id === state.id,
    );
    setActiveIndexes([newIndex]);
  }, [currentEntityState]);

  const onClick =
    (id: number | string, update: IUpdateAction, status: IStatus) =>
    () => {
      if (
        !canEdit ||
        experimentWorkflow.is_template ||
        status?.isFetching
      ) {
        return;
      }

      if (id === experimentWorkflow.entity_state.id) {
        return;
      }
      if (
        !experimentWorkflow.entity_state.deleted_at &&
        experimentWorkflow.getIsForwardEntityState(id)
      ) {
        toggleModal({ experimentWorkflow });
        return;
      }
      update({ entity_state_id: id });
    };

  return (
    <div
      ref={divRef}
      className={styles.statusIndicatorParentDiv}
      data-testid="entity-state-workflow-status-indicator"
    >
      <EntityUpdateContainer
        entityName={experimentWorkflow.entityName}
        id={experimentWorkflow.id}
      >
        {({ update, status }) => {
          return size === Size.medium || size === Size.large ? (
            <Flex wrap data-testid="status-indicator">
              {entityStates.map((entityState: EntityState, index) => {
                let position = StatusPosition.middle;
                if (index === 0) {
                  position = StatusPosition.start;
                }
                if (index + 1 === entityStates.length) {
                  position = StatusPosition.end;
                }
                return (
                  <Status
                    key={entityState.position}
                    icon={entityState.icon}
                    name={entityState.name}
                    color={entityState.color}
                    position={position}
                    small={size === Size.medium}
                    onClick={onClick(entityState.id, update, status)}
                    nextColor={
                      index + 1 < entityStates.length
                        ? entityStates[index + 1].color
                        : undefined
                    }
                    activeIndexes={activeIndexes}
                    index={index}
                    changeIndex={setActiveIndexes}
                  />
                );
              })}
            </Flex>
          ) : (
            <DropdownPill
              triggerIcon={
                entityStates[activeIndexes[0]].icon as SemanticICONS
              }
              activeLabel={entityStates[activeIndexes[0]].name}
              backgroundColor={entityStates[activeIndexes[0]].color}
              items={entityStates.map((entityState) => ({
                label: entityState.name,
                onClick: onClick(entityState.id, update, status),
              }))}
            />
          );
        }}
      </EntityUpdateContainer>
    </div>
  );
};

export default WorkflowStatusIndicator;
