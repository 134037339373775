/**
 * Labstep
 *
 * @module components/Signature/List
 * @desc Signature list
 */

import Flex from 'labstep-web/core/Flex';
import { EntityState } from 'labstep-web/models/entity-state.model';
import React from 'react';
import SignatureItem from '../Item';
import SignatureListEntityState from './EntityState';
import styles from './styles.module.scss';
import { ISignatureListProps } from './types';

const SignatureList: React.FC<ISignatureListProps> = ({
  experimentWorkflow,
}) => {
  if (!experimentWorkflow.entity_state_workflow) {
    return (
      <div className={styles.list} data-testid="signature-list">
        {experimentWorkflow.signatures.map((signature) => (
          <SignatureItem
            key={signature.id}
            signature={signature}
            experimentWorkflow={experimentWorkflow}
          />
        ))}
      </div>
    );
  }

  return (
    <Flex column data-testid="workflow-signature-list">
      {experimentWorkflow.entity_state_workflow?.entity_states
        .filter(
          (entityState: EntityState) =>
            entityState.entity_user_role_requirements.length > 0,
        )
        .map((entityState: EntityState) => (
          <SignatureListEntityState
            key={entityState.id}
            entityState={entityState}
            experimentWorkflow={experimentWorkflow}
          />
        ))}
    </Flex>
  );
};

export default SignatureList;
