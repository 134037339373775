/**
 * Labstep
 *
 * @module components/Profile/Picture/Image
 * @desc Image for profile picture
 */

import Profile from 'labstep-web/components/User/Show/Profile';
import { ThumbnailContainer } from 'labstep-web/containers/File/Thumbnail';
import { withResponsive } from 'labstep-web/containers/Responsive';
import Image from 'labstep-web/core/Image';
import Initials from 'labstep-web/core/Initials';
import Popup from 'labstep-web/core/Popup';
import React from 'react';
import {
  ILinkedProfileProps,
  IProfilePictureImageProps,
  IProfilePicturePopupProps,
} from './types';

export const LinkedProfile: React.FC<ILinkedProfileProps> = ({
  linkable,
  entity,
  children,
}) => {
  if (!linkable) {
    return <>{children}</>;
  }
  switch (entity.entityName) {
    case 'user':
      return <Profile user={entity} viewComponent={children} />;
    default:
      return <>{children}</>;
  }
};

export const ProfilePictureImage: React.FC<
  IProfilePictureImageProps
> = (props) => {
  const { entity, linkable, size, ...rest } = props;

  const { profile } = entity;

  let dimensions: number | undefined;
  if (size === 'mini') {
    dimensions = 30;
  } else if (size === 'tiny') {
    dimensions = 50;
  } else if (size === 'small') {
    dimensions = 85;
  } else if (size === 'medium') {
    dimensions = 120;
  }

  const params = {
    w: dimensions,
    h: dimensions,
    mask: rest.circular === false ? undefined : 'ellipse',
    fit: 'crop',
    crop: 'faces',
  };

  const initials = (
    <LinkedProfile {...{ linkable, entity }}>
      <Initials
        name={entity.name}
        themeId={entity.id}
        size={size}
        {...rest}
      />
    </LinkedProfile>
  );

  if (!profile || !profile.photo) {
    return initials;
  }

  const profileImage = (
    <ThumbnailContainer file={profile.photo} params={params}>
      {({ src, onError, status }) => {
        if (status !== 'loaded') {
          return initials;
        }
        return (
          <Image
            src={src}
            alt="Profile"
            size={size}
            width={dimensions}
            height={dimensions}
            circular
            centered
            onError={onError}
            {...rest}
          />
        );
      }}
    </ThumbnailContainer>
  );

  return (
    <LinkedProfile {...{ linkable, entity }}>
      {profileImage}
    </LinkedProfile>
  );
};

ProfilePictureImage.defaultProps = {
  linkable: true,
};

const ProfilePictureImageContainer = withResponsive(
  ProfilePictureImage,
  'tiny',
);

const ProfilePictureImagePopup: React.FC<
  IProfilePicturePopupProps
> = ({ popup, content, ...rest }) =>
  popup ? (
    <Popup
      size="mini"
      content={content || rest.entity.name}
      position="top center"
      trigger={
        <div>
          <ProfilePictureImageContainer {...rest} />
        </div>
      }
    />
  ) : (
    <ProfilePictureImageContainer {...rest} />
  );

export default ProfilePictureImagePopup;
